<template>
  <b-form-group
      label="Associa un servizio"
      label-for="tags-component-select"
  >
    <b-form-select
        v-model="value"
        @change="$emit('setSelect', value)"
        :options="items"
        size="lg"
    >
      <template #first>
        <option disabled value="">Scegli tra...</option>
      </template>
    </b-form-select>
  </b-form-group>
</template>

<script>
export default {
  name: "TypeServicesSelect",
  data: () => ({
    value: '',
  }),
  computed: {
    availableOptions() {
      this.$emit('setSelect', this.value)
      return this.items;
    },
    items () {
      return this.$store.state.serviceTags
    }
  },
  async created() {
    await this.getList()
  },
  methods: {
    async getList() {
      await this.$store.dispatch("setTypeServiceSelect", [])
      const res = await this.axios.get('type-of-services')
      await this.$store.dispatch("setTypeServiceSelect", res.data)
    }
  }
};
</script>